import { getAppFlavor } from "./apis/get-app-flavor";
import type { MainProp } from "./_model";
import { whitelabel$ } from ":public-dynamic/whitelabel";
import { useEffect } from "react";
import { $store } from "./store";
import { createPortal } from "react-dom";
export default function AppFlavor(props: MainProp) {
  // on mounting | setup
  useEffect(() => {
    getAppFlavor().then((value) => {
      $store.set(value);
      const active_vars =
        value.theme?.user_index || value.theme?.admin_index || 0;
      whitelabel$.CONST.THEME_VARS.set(
        value.theme?.schemes[active_vars] as any
      );

      if (props.onLoaded) {
        props.onLoaded(value);
      }
    });
  }, []);

  return createPortal(<></>, document.getElementById("whitelabel")!);
}
